.line {
  height: 20px;
width: 1px;
display: inline-block;
background-color: #fff;
transform: translateY(4px);
}

.loginFormContainer {
  width: 100%;
  max-width: 413px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-wrapper:last-of-type {
  margin-bottom: 0;
}

.label-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  font-weight: 700;
  padding: 3px 10px 0;
  color: #ffffff40;
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out,
    color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: text; 
}

.input-field {
  width: 100%;
  font-family: Poppins , sans-serif!important;;
  align-self: center;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  padding: 14px 31px 10px;
  border: none;
  outline: none;
  border-radius: 24px;
  background-color: #050d1560;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

/* Focus styles */
.input-field:focus {
  outline: none;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 4px 1px #ffffff50;
  background-color: transparent;
  /* background-color: #1e252c; */
}

.input-field:not(:focus):valid {
  border: 0.5px solid #ffffff;
}

.input-field:placeholder-shown {
  border: 0.5px solid #484c50;
  background-color: #1e252c;
}

.input-field:focus + .label-text,
.input-field:not(:focus):valid + .label-text,
.input-field:autofill .label-text, 
.input-field:-webkit-autofill .label-text,
.input-field:placeholder-shown + .label-text {
  transform: translateY(-34px) translateX(10px);
  font-size: 12px;
  color: #ffffff;
}

.input-field:not(:focus):valid + .label-text,
.input-field:autofill + .label-text, 
.input-field:-webkit-autofill + .label-text,
.input-field:placeholder-shown + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #1e252c 50%);
}

.login-input1:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #182e31 50%);
}

.login-input2:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #081418 50%);
}

.forgot-paswrd:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #081018 50%);
}

.new-paswrd1:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #213954 50%);
}

.new-paswrd2:focus + .label-text {
  background-image: linear-gradient(180deg, transparent 50%, #101c29 50%);
}

.input-field:placeholder-shown + .label-text {
  color: #ffffff40;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

@keyframes autofill {
  0%,
  100% {
    color: #fff;
    background: transparent;
  }
}

.input-field:-webkit-autofill {
  -webkit-animation-delay: 1s !important;
  -webkit-animation-name: autofill !important;
  -webkit-animation-fill-mode: both !important;
}

.input-field:autofill {
  animation-delay: 1s !important;
  animation-name: autofill !important;
  animation-fill-mode: both !important;
}

.loginActions {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Checkbox styles */
.checkbox-wrapper {
  width: auto;
  align-self: center;
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  margin-top: -3px;
  background-color: #050d1550;
  border: 0.5px solid #ffffff50;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #72efdd50;
  border: none;
}

/* When the checkbox is checked, add a blue background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #72efdd;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #1e252c;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*  Create password page styles */
.passwordIconWrapper {
  position: absolute;
  top: 11px !important;
  right: 14px !important;
}

.eyeIcon {
  color: #a9a5a5;
  font-size: 1.4rem;
  transition: color 0.3s ease-in-out;
}

.input:focus ~ .eyeIcon {
  color: #ffffff;
}

.validation-container {
  margin-left: 40px;
}

.validation-wrapper {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}

.validation-container .validation-wrapper:last-child {
  margin: 0;
}

.cirleIcon,
.checkIcon,
.dashIcon .validationText,
.notValid,
.isValid {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #ffffff;
}

.notValid {
  color: #f87171;
}

.isValid {
  color: #72efdd;
}

/* Creat country page styles */
.inputWrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-image: url('../../assets/skipArrow.svg'); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.phoneInputWrapper {
  position: absolute;
  top: 12px;
  left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 108px;
}
.flag {
  width: 30px;
  height: 20px;
  border-radius: 8px;
  overflow: hidden;
}
.phoneInputWrapper span {
  color: #ffffff;
}
.phoneInput {
  padding-left: 140px;
  padding-right: 40px;
}
.divider {
  border: 0.5px solid #ffffff50;
  height: 15px;
  margin: 0 10px;
}

.phoneIconWrapper {
  position: absolute;
  top: 11px;
  right: 10px;
}

.phoneIcon {
  font-size: 1.3rem;
}

.typingWrapper {
  border: 1px solid #fef3c7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing {
  font-size: 0.6rem;
  color: #fef3c7;
}

.validPhone {
  color: #72efdd;
}

.invalidPhone {
  color: #f87171;
}

.headingStyle {
  width: 100%;

}
.heading3style {
  background: -webkit-linear-gradient(180deg, #72efdd, #26c5f3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading2style {
  font-weight: 300 !important;
}

.forgotPswrdTxt {
  text-decoration: underline;
  cursor: default;
  max-width: 150px;
  font-weight: 700 !important;
}

.popupErrorStyle{
  top: 140px;
}

.popupErrorStyle-profile{
  top: 20%;
  position: fixed;
}

/* Media Queries */
@media screen and (min-width: 400px) {
  .headingStyle {
    width: 100%;
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294773 50%);
  }
  
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182942 50%);
  }
}
@media screen and (min-width: 600px) {
  .input-wrapper{
    width: 100%;
  }
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182e31 50%);
  }
  
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #081418 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294a73 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182942 50%);
  }
  .loginActions {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .popupErrorStyle {
    top: 134px;
    width: 95%;
    right: 50%;
    transform: translate(50%, 0);
  }
}

@media screen and (min-width: 800px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #203840 50%);
  }
}

@media screen and (min-width: 1024px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #29525a 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #2a4b74 50%);
  }
}

@media screen and (min-width: 1100px) {
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182931 50%);
  }
}

@media screen and (min-width: 1200px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #396b6b 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213d42 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18293f 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213962 50%);
  }
}

@media screen and (min-width: 1300px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #315e60 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #295a5a 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18314a 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #213d5a 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #28416a 50%);
  }
}

@media screen and (min-width: 1400px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294e51 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #315e63 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #29426b 50%);
  }
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #183143 50%);
  }
}

@media screen and (min-width: 1500px) {
  .new-paswrd1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #102129 50%);
  }
}
@media screen and (min-width: 1600px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #294e51 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #183639 50%);
  }
  .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #182d42 50%);
  }
}

@media screen and (min-width: 1700px) {
  .login-input1:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #152026 50%);
  }
  .login-input2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #15252b 50%);
  }
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #18314a 50%);
  }
}

@media screen and (min-width: 1800px) {
  .forgot-paswrd:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #101921 50%);
  }
  .new-paswrd1:focus + .label-text, .new-paswrd2:focus + .label-text {
    background-image: linear-gradient(180deg, transparent 50%, #101821 50%);
  }
}